.banner {
    display: block;
    @include container;
    text-align: center;
    background-color: $darkgreen;
    color: white;
    padding: 40px 0;
    @media #{$breakpoint-xlarge} {
        padding: 80px 0;
    }

    &.banner-slim {
        @media #{$breakpoint-large} {
            padding: 55px 0 !important;
        }
    }

    h1, h2 {
        margin: 0;
        //padding: 0 20px;
        color: white;
    }

    p {
        font-size: rem(16px);
        padding: 0 30px;
        color: white;
        margin: 15px 0 0;
        font-weight: 500;
        @media #{$breakpoint-medium} {
            font-size: rem(18px);
            padding-left: 70px;
            padding-right: 70px;
            margin-right: auto;
            margin-left: auto;
        }
        @media #{$breakpoint-xlarge} {
            font-size: rem(24px);
            padding-left: 150px;
            padding-right: 150px;
        }

    }
    &.banner-color-evergreen {
        background-color: $evergreen;
    }
    &.banner-color-darkgreen {
        background-color: $darkgreen;
    }
    &.banner-color-green {
        background-color: $green;
    }

    &.banner-color-greenbg {
        background-color: rgba($green, .20);
        background-image: url('/images/ralston-background-illustration-green.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;

        h1, h2 {
            color: $brown;
        }
        p, li {
            color: $gray;
        }
    }
    &.banner-color-lime {
        background-color: $lime;
    }
    &.banner-color-orange {
        background-color: $orange;
    }
    &.banner-color-blue {
        background-color: $blue;
    }
    &.banner-color-darkblue {
        background-color: $darkblue;
    }
    &.banner-color-purple {
        background-color: $purple;
    }
    &.banner-color-teal {
        background-color: $teal;
    }
    &.banner-color-gray {
        background-color: $gray-light;
        h1, h2 {
            color: $brown;
        }
        p, li {
            color: $gray;
        }
    }

    &.has-image-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.has-hero {
        padding: 40px 0;
        @media #{$breakpoint-medium} {
            padding: 50px 0;
        }

        .button {
            margin-top: rem(20px);
        }

    }
}

// ------------------------------------------------------------------------------------------------------------------ //

#promo-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: $orange;
  text-align: center;
  padding: 8px 10px;
  //border-bottom: 5px solid $evergreen;
  position: relative;
  z-index: 100;
  @media #{$breakpoint-large} {
    padding: 8px 20px;
  }

  p, a, span {
    font-family: $tradegothic;
    color: white;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-style: italic;
    @media #{$breakpoint-large} {
      font-size: 16px;
    }
  }
}

#offline {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: $red;
    text-align: center;

    p {
        font-family: $tradegothic;
        color: white;
        font-weight: bold;
        font-size: 15px;
    }
}

.product-callout {
    display: flex;
    @include container;
    text-align: center;
    background-color: white;
    color: $gray;
    padding: 30px 0 8px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media #{$breakpoint-large} {
        padding: 80px 0 75px 0;
        flex-direction: row;
    }

    img {
        max-width: 250px;
        margin: 0;
        @media #{$breakpoint-medium} {
            max-width: 400px;
            margin: 0 150px;
        }
    }
    .text {
        min-width: 40%;

        h2 {
            color: $red;
            margin-bottom: 0;
        }

        .product-subtitle {
            display: block;
            color: $red;
            font-family: $tradegothic;
            font-size: rem(14px);
            padding-bottom: 20px;
            @media #{$breakpoint-medium} {
                font-size: rem(20px);
            }
        }

        p {
            color: $gray;
            padding: 0 10px;
        }

        .logos {
            padding: 15px 0;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;

            a:first-child {
                @media #{$breakpoint-medium} {
                    margin-right: 35px;
                }
            }

            a { margin-bottom: 15px; }
            @media #{$breakpoint-medium} {
                flex-direction: row;
                a { margin-bottom: 0; }
            }
        }
    }
}

// ------------------------------------------------------------------------------------------------------------------ //

.alert {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 15px;
    margin-bottom: 25px;

    p, li, span {
        font-family: $tradegothic;
        color: white;
        font-weight: bold;
        font-size: 15px;
    }
    h3 {
        font-size: rem(15px);
        padding: 0 20px;
        color: $gray-light;
        margin-bottom: 10px;
        @media #{$breakpoint-medium} {
            font-size: rem(20px);
            margin: 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 10px;

            span {
                text-transform: capitalize;
                font-weight: bold;
            }
        }
    }

    &.success {
        background-color: $green;
    }
    &.error {
        background-color: $red;
    }
}

.cookie-consent {
    display: flex;
    background-color: $lime;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: sticky;
    bottom: 0;
    margin-bottom: 0;
    z-index: 999;
    width: 100%;
    text-align: center;
    padding: 20px 15px;
    @media #{$breakpoint-medium} {
        flex-direction: row;
    }

    h3 {
        font-size: rem(12px);
        padding: 0 20px;
        color: white;
        margin-bottom: 10px;
        @media #{$breakpoint-medium} {
            font-size: rem(16px);
            margin: 0;
        }
    }
    a { color: white; }
    button {
        border: none;
        background-color: $orange;
        color: white;
    }
}
