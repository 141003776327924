// -----------------------------------
// Subscribe to our Newsletter
// -----------------------------------
.subscribe {
    @include container;
    background-color: $teal;
    text-align: center;
    padding: 50px 38px;
    @media #{$breakpoint-medium} {
        padding: 70px 38px;
    }

    h3 {
        font-size: rem(24px);
        text-transform: uppercase;
        margin: 0 0 22px 0;
        letter-spacing: 0.045em;
        color: $white;
        @media #{$breakpoint-medium} {
            font-size: rem(28px);
            margin: 0 0 22px 0;
        }
    }

    p {
        font-size: rem(14px);
        color: $white;
        @media #{$breakpoint-medium} {
            font-size: rem(18px);
        }
    }

    form {
        padding-left: 0;
        padding-right: 0;

        .newsletter-inputs {
            display: grid;
            margin-bottom: 10px;
            grid-template-columns: repeat(1, minmax(0, 1fr));

            label {
                color: $white;
            }

            @media #{$breakpoint-medium} {
                gap: 15px;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                max-width: 1024px;
                width: 100%;
                margin: 0 auto;

                label {
                    text-align: left;
                }
            }

            @media #{$breakpoint-large} {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }

        .has-captcha .g-recaptcha {
            display: flex;
            justify-content: center;
        }
    }

    //form {
    //    display: block;
    //    padding: 15px 0 0 0;
    //    text-align: center;
    //}
    //
    //input:not([type=submit]) {
    //    display: block;
    //    height: 48px;
    //    max-width: 750px;
    //    width: 100%;
    //    margin: 0 auto 36px auto;
    //    text-align: center;
    //    font-size: rem(14px);
    //    color: $evergreen;
    //    border: none;
    //    @media #{$breakpoint-medium} {
    //        height: 90px;
    //        font-size: rem(22px);
    //    }
    //}
}
