button:not([data-controls="prev"]):not([data-controls="next"]):not([aria-controls="tns1"]):not(.gm-control-active):not(.quantity-button):not(.cart-nav-button):not(#close-cart-button):not(.target-burger):not(.product-image-button),
.button {
    display: inline-flex;
    font-family: $arquitecta;
    padding: 18px 30px 15px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: rem(16px);
    line-height: 1;
    letter-spacing: 0.08em;
    font-weight: 700;
    min-width: 250px;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    border: 0;
    text-align: center;
    @media #{$breakpoint-medium} {
        margin: 0;
        min-width: auto;
    }
    &:hover {
        cursor: pointer;
    }

    &.primary {
        background-color: $orange;
        color: white;
        &:hover {
            background-color: $lime;
        }
    }

    &.secondary {
        border: 1px solid $orange;
        background-color: white;
        color: $orange;
        &:hover {
            border: 1px solid $lime;
            background-color: white;
            color: $lime;
        }
    }

    &.secondary-to-primary {
        background-color: white;
        color: $orange;
        &:hover {
            color: $lime
        }
    }

    &.ghost {
        background-color: transparent;
        border: 2px solid white;
        color: white;
        &:hover {
            background-color: white;
            color: $orange;
        }
    }

    &.golden {
        background-color: $orange;
        color: white;
    }

    &.orange {
        background-color: $orange;
        color: white;
    }

    &.gray {
        border: 1px solid $gray;
        background-color: white;
        color: $gray;
        &:hover {
            cursor: not-allowed !important;
            background-color: $gray-light;
            color: $black;
        }
    }
}

.banner-color-green,
.banner-color-red,
.banner-color-lime,
.banner-color-blue,
.banner-color-purple {
    .button {
        background-color: $lightpurple;
        color: $white;
        &:hover {
            color: $white;
        }
    }
}

.banner-color-green,
.banner-color-red,
.banner-color-lime,
.banner-color-blue,
.banner-color-purple {
    .button {
        background-color: transparent;
        border: 2px solid white;
        color: white;
        &:hover {
            background-color: white;
            color: $orange;
        }
    }
}




button.gm-control-active {
    color: $red;
}
