.cart-nav-button {
    padding: 0;
    position: relative;
    &:hover {
        cursor: pointer;
    }

    .count {
        position: absolute;
        color: $white;
        background: $orange;
        border-radius: 50%;
        font-family: Arial, sans-serif;
        right: -1px;
        top: -5px;
        font-size: 8px;
        height: 15px;
        width: 15px;
        padding: 3px;
        @media #{$breakpoint-medium} {
            font-size: 10px;
            height: 18px;
            width: 18px;
            right: -4px;
            top: -8px;
        }
    }
}

#cart {
    display: none;
    &.active {
        display: flex;
        flex-direction: column;
        padding: 30px;
        position: absolute;
        right: 0;
        min-height: 100vh;
        height: 100%;
        width: 95%;
        background-color: white;
        z-index: 100;
        box-shadow: 0 4px 13px 0 rgba(0,0,0,.24), 0 1px 4px 0 rgba(0,0,0,.19);
        @media #{$breakpoint-large} {
            width: 60%;
        }
        @media #{$breakpoint-xlarge} {
            width: 45%;
        }

        h2 {
            color: $black;
            margin: 0;
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a, button {
                text-decoration: underline;
                font-size: rem(14px);
                margin-right: 10px;
                background: none;
                border: none;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        hr {
            width: 100%;
            margin: 15px 0;
        }

        #close-cart-button {
            display: inline-flex !important;
        }
    }

    #close-cart-button {
        display: none;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 0;
        background: transparent;
        border: 1px solid $black;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        i { color: $black; }
        &:hover {
            cursor: pointer;
            background-color: $black;
            i { color: white; }
        }
    }

    .line-item {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 10px;
        margin-bottom: 25px;
        &:hover {
            background-color: $gray-light;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 25px;
            text-align: center;
            @media #{$breakpoint-medium} {
                flex-direction: row;
                justify-content: space-between;
                text-align: initial;
            }

            h3 {
                margin-bottom: 0;
                font-size: rem(16px);
                @media #{$breakpoint-medium} {
                    font-size: rem(22px);
                }
            }
            h4 {
                margin: 5px 0 0;
                font-size: rem(14px);
            }

            p {
                margin-bottom: 0;
                font-size: rem(16px);
            }

            .price {
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: center;
                @media #{$breakpoint-large} {
                    align-items: end;
                }

                p {
                    @media #{$breakpoint-large} {
                        font-size: rem(18px);
                    }
                }

                a {
                    color: $gray;
                    &:hover {
                        color: $orange;
                        cursor: pointer;
                    }
                }
            }

            .details {
                flex: 1;
                text-align: center;
                @media #{$breakpoint-medium} {
                    text-align: left;
                    padding-left: 35px;
                }
            }

            .variants {
                p {
                    margin: 0 !important;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media #{$breakpoint-medium} {
                flex-direction: row;
                max-height: 50px;
            }

            .quantity-selector {
                margin-bottom: 20px;
                @media #{$breakpoint-medium} {
                    margin-bottom: 0;
                }
            }

            button {
                margin: 0 !important;
            }
        }

        img {
            max-width: 150px !important;
        }
    }

    .subtotal {
        text-align: center;
        padding: 20px 10px;
        border-top: 1px solid $gray-light2;
        margin-top: 20px;

        h3 {
            color: $blue;
            margin: 0 0 10px 0;
        }

        p {
            margin: 0;
        }
    }

    .notes {
        padding: 10px !important;

        h3 {
            margin: 0 0 5px 0;
            text-align: center;
            font-size: rem(14px);
        }

        span {
            font-family: $tradegothic;
            font-size: rem(14px);
            line-height: 1.3em;
            font-weight: bold;
            @media #{$breakpoint-medium} {
                font-size: rem(16px);
                line-height: 1.65em;
            }

            &.success {
                color: $green;
            }
            &.error {
                color: $red;
            }
        }

        textarea {
            &.success {
                border: 2px solid $green !important;
            }
            &.error {
                border: 2px solid $red !important;
            }
        }
    }
}
