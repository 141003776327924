.wrapper-1920 {
    display: block;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.container {
    display: block;
    max-width: 1935px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    &#where-to-buy {
        padding: 0 !important;
    }
}
.customer,
.slim-container {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 20px;
    @media #{$breakpoint-medium} {
        padding: 0 100px;
    }
    @media #{$breakpoint-xlarge} {
        padding: 0 350px;
    }
    @media #{$breakpoint-mega} {
        padding: 0 500px;
    }
}

.customer {
    padding-top: 25px;
    padding-bottom: 25px;
}

.blog {
    h2 {
        margin-bottom: 10px;
    }

    h4 {
        color: $blue;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    p {
        margin-top: 0;
        margin-bottom: 30px;
        line-height: 1.5rem;
    }

    .html-container {
        width: 100%;
        display: block;
        margin: 0 auto;
        padding: 0 20px;
        @media #{$breakpoint-medium} {
            padding: 0 100px;
        }
        @media #{$breakpoint-xlarge} {
            padding: 0 350px;
        }
        @media #{$breakpoint-mega} {
            padding: 0 500px;
        }

        img {
            padding-bottom: 30px;
        }
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 30px;

        li p,
        li p {
            margin: 0;
        }
    }
}

.hidden:not(#body) {
    display: none;
}

// SHOPIFY

.customer__heading {
    margin: 35px 0 0 0;
    text-transform: uppercase;
    text-align: center;
    font-size: rem(20px);
    color: $darkblue;
    @media #{$breakpoint-medium} {
        font-size: rem(32px);
    }
}

input.shopify-challenge__button {
    display: inline-flex;
    font-family: $tradegothic;
    padding: 15px;
    text-decoration: none;
    font-style: italic;
    font-size: rem(20px);
    letter-spacing: 0rem;
    min-width: 250px;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    border: 0;
    background-color: $blue;
    color: white;
    @media #{$breakpoint-medium} {
        margin: 0;
    }
    &:hover {
        cursor: pointer;
    }
}

// Shopify
a.customer__link {
    display: block;
    color: $gray;
    font-family: $tradegothic;
    font-size: 14px;
    margin: 20px 0;
}
button.customer__button {
    background-color: $blue;
    color: white;
}

table#orders-table {
    max-width: 900px;

    th, td {
        text-align: center;
        padding: 10px;
        border: 1px solid $gray;
        font-family: $tradegothic;
        font-size: rem(14px);
        line-height: 1.45em;
        color: $darkblue;
        @media #{$breakpoint-medium} {
            font-size: rem(18px);
            line-height: 1.65em;
        }
    }

    tr:hover {
        td {
            background-color: $gray-light;
        }
    }
}
