#profile-navigation {
    display: flex;
    flex-direction: column;
    padding: 50px 15px 25px 15px;
    background: $evergreen;
    justify-content: center;
    @media #{$breakpoint-medium} {
        flex-direction: row;
        padding: 100px 15px 50px 15px;
    }

    a {
        color: white;
        text-decoration: none;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        margin-bottom: 25px;
        position: relative;
        @media #{$breakpoint-medium} {
            flex-basis: 15%;
            margin-bottom: 0;
        }

        &:hover {
            color: $teal;
        }

        &.active {
            &.active::after {
                content: '';
                position: absolute;
                width: 50%;
                bottom: -15px;
                border: 1px solid $teal;
            }
        }
    }
}
