select {
    display: inline-flex;
    font-family: $tradegothic;
    padding: 10px 35px 6px 10px;
    font-size: rem(18px);
    letter-spacing: 0rem;
    min-width: 200px;
    justify-content: center;
    border: 1px solid $blue;
    color: $blue;
    &:hover {
        cursor: pointer;
    }

    // Arrow hiding
    -moz-appearance: none;
    -webkit-appearance: none;
    &::-ms-expand {
        display: none;
    }
}

.select {
    display: inline-flex;
    cursor: pointer;
    font-size: 1em;
    max-width: 100%;
    outline: 0;
    position: relative;

    &::after {
        border: 3px solid $blue;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: .625em;
        margin-top: -.4375em;
        pointer-events: none;
        position: absolute;
        top: 47%;
        transform: rotate(-45deg);
        transform-origin: center;
        width: .625em;
        right: 1.125em;
        z-index: 4;
        transition: all .2s linear;
    }
}

// -----------------------------------
// Recipe Search
// -----------------------------------
#search-filters {
    margin: 15px 20px;
    padding: 10px;
    @media #{$breakpoint-medium} {
        margin: 0;
        padding: 15px 100px 50px 100px;
    }

    &.checked-active {
        border: 1px solid $blue;

        .accordion {
            border: 0 !important;
            &:after {
                transform: rotate(135deg) !important;
                margin-top: -.2375em !important;
            }
        }

        .filters {
            display: flex !important;
        }
        .header {
            display: block !important;
        }
    }

    // Mobile accordion
    .accordion {
        display: none;
        font-family: $tradegothic;
        padding: 15px 35px 10px 10px;
        font-size: rem(18px);
        letter-spacing: 0;
        min-width: 200px;
        justify-content: center;
        border: 1px solid $blue;
        color: $blue;
        &:hover {
            cursor: pointer;
        }
    }
    @media only all and (max-width: 768px) {
        .accordion {
            display: flex;
        }
        .header, .filters {
            display: none !important;
        }
    }

    .filters {
        display: flex;
        flex-direction: column;
        @media #{$breakpoint-medium} {
            display: block;
        }

        .select {
            margin-bottom: 15px;
            margin-right: 0;
            @media #{$breakpoint-medium} {
                margin-right: 10px;
            }

            select {
                width: 100%;
                background: white;
            }
        }
    }

    p {
        span {
            color: $black;
            font-weight: bold;
            padding-right: 10px;
            font-size: rem(16px);
            text-transform: uppercase;
        }
        a {
            font-size: rem(12px);
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 35px;
    background-color: #f8f8f7;
}

// -----------------------------------
// Contact/Job Forms
// -----------------------------------
form:not(.checkout-nav-form-button), .form {
    padding-top: 25px !important;
    padding-bottom: 50px !important;

    .input, .form__input-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        label {
            font-family: $tradegothic-bold;
            margin-bottom: 5px;

            &.required {
                &::after {
                    content: '*';
                    //color: $blue;
                    margin-left: 5px;
                }
            }
        }

        input:not([type=submit]), textarea, select {
            display: block;
            font-size: rem(14px);
            color: $blue !important;
            padding: 10px;
            border: 1px solid #d3d3d3 !important;
            font-family: $tradegothic;
            font-style: normal;
            @media #{$breakpoint-medium} {
                font-size: rem(18px);
            }
        }

        textarea {
            position: relative;
            max-width: 100%;
        }
    }

    .has-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            margin: 0;
        }
        input {
            margin: 0 10px 4px 0;
        }
    }

    .has-select {
        position: relative;

        &::after {
            border: 3px solid $blue;
            border-radius: 2px;
            border-right: 0;
            border-top: 0;
            content: " ";
            display: block;
            height: .625em;
            margin-top: -.4375em;
            pointer-events: none;
            position: absolute;
            top: 65%;
            transform: rotate(-45deg);
            transform-origin: center;
            width: .625em;
            right: 1.125em;
            z-index: 4;
        }
    }

    .has-radio {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            color: $gray;
        }
    }

    .g-recaptcha > div > div {
        height: 100% !important;
    }

    .rating-radios {
        display: flex;
        flex-direction: row;

        span {
            position: relative;
            margin-right: 10px;
        }

        input[type=radio] {
            position: absolute;
            margin: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            appearance: none;
            padding: 0 !important;
            border: none !important;
            box-shadow: none;
        }
    }

    button {
        margin: 0 !important;
        width: 100%;
        @media #{$breakpoint-medium} {
            width: initial;
        }
    }

    a.underlined {
        color: $gray;
    }

    .grid {
        padding: 0;
    }
}

// -----------------------------------
// Create a custom radio button
// -----------------------------------
.has-radio {
    display: block;
    position: relative;
    //padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 35px;
    align-items: center;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 100%;
        width: 120%;
        left: -35px;
        top: 0;
        z-index: 5;
    }

    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
}

.checkmark {
    position: absolute;
    top: 4px;
    left: -27px;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid $gray;
    border-radius: 50%;
    opacity: 0 !important;
    @media #{$breakpoint-medium} {
        top: 0px;
        left: -35px;
        height: 25px;
        width: 25px;
    }

    // Create the indicator (the dot/circle - hidden when not checked)
    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

// Show the indicator (dot/circle) when checked
.has-radio input:checked ~ .checkmark:after {
    display: block;
}

// Style the indicator (inner dot/circle)
.has-radio .checkmark:after {
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $blue;
    @media #{$breakpoint-medium} {
        top: 3px;
        left: 3px;
        width: 17px;
        height: 17px;
    }
}
