//@import '../fonts/fonts';
//$work-sans-bold: 'work-sans-bold', Helvetica, Arial, sans-serif;
//$work-sans-semibold: 'work-sans-semibold', Helvetica, Arial, sans-serif;
//$work-sans-medium: 'work-sans-medium', Helvetica, Arial, sans-serif;
//$work-sans-regular: 'work-sans-regular', Helvetica, Arial, sans-serif;
//$work-sans-light: 'work-sans-light', Helvetica, Arial, sans-serif;

$background: #eff2f3;
$white: #ffffff;
$black: #2d2828;

$gray: #7a7573;
$gray-light: #f8f8f7;
$gray-light2: #D6D6D7;
$gray-dark: #212121;

$orange: #CB6800;
$lightyellow: #f7e48d;
$yellow: #CC9E0F;
$red: #B30900;
$brown: #3F2021;

$lime: #9cb92d;
$green: #46902E;
$darkgreen: #114216;

$teal: #1e978a;
$evergreen: #072a31;

$lightblue: #dff3f4;
$blue: #0072CD;
$darkblue: #0D2879;
$navy: #002554;

$lightpurple: #AC53B6;
$purple: #403744;
