// --- SLIDER
.slider-wrapper {
  display: block;
  max-height: 1920px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding-bottom: 5px;

  .slide {
    display: block;
    position: relative;

    img {
      display: block;
      position: relative;
      z-index: 1;
      margin: 0 auto;
    }

    //&:before {
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  background: rgba(33, 33, 33, 0.1);
    //  z-index: 2;
    //}

    .slide-info {
      display: block;
      width: 100%;
      z-index: 2;
      color: $evergreen;
      background: $lightblue;
      padding: 30px 20px;
      @media #{$breakpoint-medium} {
        background: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
      }
    }

    .title {
      @extend h1;
      display: block;
      font-size: rem(20px);
      line-height: .95em;
      padding: 0 10px;
      margin: 10px 0;
      color: $evergreen;
      @media all and (min-width: 480px) {
        font-size: rem(26px);
      }
      @media #{$breakpoint-medium} {
        color: white;
        font-size: rem(32px);
        text-shadow: 3px 3px rgb(33, 33, 33);
      }
      @media all and (min-width: 1000px) {
        font-size: rem(40px);
      }
      @media all and (min-width: 1500px) {
        font-size: rem(60px);
      }
    }

    .subtext {
      @extend h4;
      display: block;
      font-size: rem(16px);
      padding: 0 10px;
      line-height: 1.2em;
      margin-bottom: 20px;
      color: $gray;
      font-family: $tradegothic;
      @media all and (min-width: 480px) {
        font-size: rem(18px);
        margin-bottom: 30px;
      }
      @media #{$breakpoint-medium} {
        color: white;
        letter-spacing: 0.03em;
        font-weight: bold;
        text-shadow: 3px 3px rgb(33, 33, 33);
      }
      @media all and (min-width: 1000px) {
        font-size: rem(24px);
      }
      @media all and (min-width: 1500px) {
        margin-bottom: 90px;
      }
    }

    .button.secondary-to-primary {
        background-color: $orange;
        color: white;
        &:hover {
            background-color: $lime;
        }
    }
  }
}

// --- CAROUSEL
.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 60px;
    position: relative;

    h2 {
        margin-bottom: 50px;
    }

    .tns-controls {
        top: 0 !important;
    }
}
.carousel-wrapper {
    display: block;
    max-height: 1920px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding-bottom: 5px;

    .slide {
        display: block;
        position: relative;

        img {
            display: block;
            position: relative;
            z-index: 1;
            margin: 0 auto;
            padding: 0 20px;
            max-width: 300px;
            @media #{$breakpoint-medium} {
                max-width: 100%;
            }
        }
    }

    .tns-transparent {
        opacity: 100 !important;
        visibility: visible !important;
    }
}

// --- VARIETY
.variety-carousel {
    position: relative;
    padding-bottom: 20px;

    .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        @media #{$breakpoint-medium} {
            padding: 0 50px;
        }

        a {
            text-decoration: none;
        }

    }
}

.product-title {
    @extend h4;
    font-size: rem(16px);
    color: $evergreen;
    margin: 30px 0 0;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
    @media #{$breakpoint-medium} {
        font-size: rem(22px);
    }

    &:hover {
        color: $green;
    }
}


.product-subtitle {
    @extend h5;
    display: block;
    color: $brown;
    font-size: rem(14px);
    padding-top: 5px;
    padding-bottom: 10px;
    @media #{$breakpoint-medium} {
        font-size: rem(16px);
    }
}

.product-description {
    display: block;
    color: $gray;
    font-family: $tradegothic;
    font-size: rem(16px);
    line-height: 1.35em;
    width: 80%;
    margin: 0 auto;
    @media #{$breakpoint-medium} {
        width: 100%;
    }
}

.product-shop-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    @media #{$breakpoint-medium} {
        flex-direction: row;
    }
}

.product-shop-buttons a:first-child {
    margin-bottom: 10px;
    @media #{$breakpoint-medium} {
        margin-bottom: 0;
    }
}



// --- BULLETS
.tns-outer {
    position: relative;
    width: 100%;
}
.tns-nav {
    background: transparent;
    display: none;
    position: absolute;
    width: 100%;
    justify-content: center;
    @media #{$breakpoint-medium} {
        top: auto;
        bottom: 20px;
        display: flex;
    }

    button {
        background: $white;
        width: 1em;
        height: 1em;
        display: inline-block;
        border-radius: 1em;
        border-width: 1px;
        border-color: $red;
        border: none;
        padding: 7px !important;
        margin: 5px !important;
        color: transparent;

        &.tns-nav-active {
            background: $lime;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

// --- ARROWS
.tns-controls {
    width: 100%;
    min-width: 100%;
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    top: -100px;
    @media #{$breakpoint-medium} {
        top: 0;
    }

    button {
        display: inline-flex;
        text-decoration: none;
        text-align: center;
        font-size: 5em;
        z-index: 6;
        border: 0;
        position: absolute;
        background: transparent;
        padding: 0;
        content: ' ';

        &:hover {
            cursor: pointer;
        }

        &[data-controls="prev"] {
            left: 10px;
            justify-content: flex-start;

            &::after {
                border: 5px solid $lime;
                border-right: 0;
                border-top: 0;
                content: " ";
                display: block;
                height: .5em;
                pointer-events: none;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transform-origin: center;
                -ms-transform-origin: center;
                transform-origin: center;
                width: .5em;
                z-index: 5;
                margin-left: 10px;
            }
        }

        &[data-controls="next"] {
            right: 10px;
            justify-content: flex-end;

            &::before {
                border: 5px solid $lime;
                border-right: 0;
                border-top: 0;
                content: " ";
                display: block;
                height: .5em;
                pointer-events: none;
                -webkit-transform: rotate(-135deg);
                -ms-transform: rotate(-135deg);
                transform: rotate(-135deg);
                -webkit-transform-origin: center;
                -ms-transform-origin: center;
                transform-origin: center;
                width: .5em;
                z-index: 5;
                margin-right: 10px;
            }
        }

        span {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
        }
    }
}
