// -----------------------------------
// Welcome
// -----------------------------------
.welcome {
  display: block;
  @include container;
  text-align: center;
  background-color: $green;
  color: white;
  padding: 30px 0 8px 0;
  @media #{$breakpoint-medium} {
    padding: 80px 0 75px 0;
  }

  h1 {
    font-size: rem(18px);
    margin: 0;
    padding: 0 20px;
    color: white;
    @media #{$breakpoint-medium} {
      font-size: rem(30px);
    }
  }

  p {
    font-size: rem(14px);
    padding: 0 20px;
    color: white;
    @media #{$breakpoint-medium} {
      font-size: rem(20px);
      padding: 0 70px;
    }
  }
}

// -----------------------------------
// Innovating Rice
// -----------------------------------
.innovating-rice {
  @include container;
  text-align: center;
  background-color: $blue;
  color: white;
  padding: 50px 20px 65px 20px;
  @media #{$breakpoint-medium} {
    padding: 140px 20px 70px 20px;
    margin-top: 55px;
  }

  h3 {
    color: white;
    font-size: rem(28px);
    margin: 0 0 20px 0;
    @media #{$breakpoint-medium} {
      font-size: rem(48px);
    }
  }

  p {
    color: white;
    max-width: 1560px;
    margin: 0 auto 80px auto;
    font-size: rem(14px);
    line-height: 1.15em;
    @media #{$breakpoint-medium} {
      font-size: rem(24px);
    }
  }

  img {
    display: block;
    margin: 52px auto 0 auto;
  }
}

// -----------------------------------
// Family to Family
// -----------------------------------
.family-to-family {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include container;
  height: 480px;
  padding: 0 38px;
  background-image: url('/images/home_family_to_family.jpg');
  background-position: center;
  background-size: cover;
  text-align: center;
  @media #{$breakpoint-medium} {
    height: 800px;
  }

  h3 {
    color: white;
    font-size: rem(34px);
    margin: 0 0 20px 0;
    text-transform: uppercase;
    @media #{$breakpoint-medium} {
      font-size: rem(56px);
    }
  }

  p {
    color: white;
    max-width: 980px;
    margin: 0 auto 80px auto;
    font-size: rem(18px);
    line-height: 1.15em;
    @media #{$breakpoint-medium} {
      font-size: rem(28px);
    }
  }
}

.slider-wrapper--products {
  display: flex;
  justify-content: center;
  //max-width: 1385px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px 0 35px 0;
  @media #{$breakpoint-medium} {
    padding: 40px 0 35px 0;
  }

  .slide {
    display: block;
    max-width: 333px;
    width: 100%;
    margin: 0 20px;
    text-align: center;
    padding-bottom: 35px;
    @media #{$breakpoint-medium} {
        margin: 0 40px;
    }


    a.has-image {
        text-decoration: none;
        padding-bottom: 20px;
    }
  }

  @media #{$breakpoint-medium} {
    font-size: rem(24px);
  }
}
