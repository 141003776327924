#recipe {
	padding: 0 !important;
    gap: 0 !important;

    .recipe-image {
        grid-column: span 1 / span 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 400px;
        @media #{$breakpoint-large} {
            grid-column: span 2 / span 2;
            min-height: 800px;
        }
    }

    .recipe-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 45px 15px;
        text-align: center;
        background-color: $gray-light;
        @media #{$breakpoint-large} {
            padding: 40px;
        }

        .title {
            position: relative;
            width: 100%;
            //height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-height: 175px;
            padding-bottom: 15px;
            @media #{$breakpoint-medium} {
                min-height: 250px;
                padding-bottom: 0;
            }

            h1 {
                text-transform: uppercase;
                margin: 0;
                padding-top: 10px;
                position: relative;
                font-size: rem(32px);
                line-height: 0.95em;
                color: $evergreen;
                @media #{$breakpoint-medium} {
                    font-size: rem(56px);
                }
                @media #{$breakpoint-medium} {
                    margin-top: 0;
                }
            }

            .bg-text {
                display: block;
                position: absolute;
                z-index: 0;
                top: 10%;
                transform: rotate(-13deg);
                letter-spacing: -0.02em;
                line-height: 0.8em;
                font-family: $stash;
                font-size: rem(88px);
                color: $yellow;
                opacity: 0.10;
                @media #{$breakpoint-medium} {
                    font-size: rem(120px);
                    left: 0;
                }
                @media #{$breakpoint-large} {
                    font-size: rem(150px);
                }
                @media #{$breakpoint-mega} {
                    font-size: rem(170px);
                }
            }
        }

        h1 {
            color: $black;
            text-align: center;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            font-family: $arquitecta;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 38px; /* 105.556% */
            text-transform: uppercase;
            margin: 0 !important;
        }

        #recipe-author {
            color: $black;
            text-align: center;
            font-family: $tradegothic;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 25px;
        }

        p.description {
            color: $gray;
            font-size: rem(20px);
            @media #{$breakpoint-medium} {
                font-size: rem(22px);
            }
        }

        #made-with {
            //margin: 0;
            color: $gray;
        }

        .product {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 25px;

            img {
                max-width: 200px;
                margin: 0 auto;
            }

            h2 {
                color: $black;
                text-align: center;
                font-family: $arquitecta;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                margin-top: 25px;
                margin-bottom: 15px;
            }
        }
    }
}

.recipe-image, .product-images {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	padding: 40px 0 0 0;

	img#main-image {
		max-width: 100%;
		@media #{$breakpoint-medium} {
			max-width: 90%;
            padding: 40px 0;
		}
	}
}

#print-image {
    display: none;
}

.product-images {
    @media #{$breakpoint-medium} {
        padding: 60px;
    }

    video {
        width: 100%;
    }
}

.product-details {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	@media #{$breakpoint-xlarge} {
		padding-top: 100px;
	}

	.title {
		position: relative;
		width: 100%;
		//height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		min-height: 175px;
		padding-bottom: 15px;
		@media #{$breakpoint-medium} {
			min-height: 250px;
			padding-bottom: 0;
		}

		h1 {
			text-transform: uppercase;
			margin: 0;
			padding-top: 10px;
			position: relative;
			font-size: rem(32px);
			line-height: 0.95em;
			color: $evergreen;
			@media #{$breakpoint-medium} {
				font-size: rem(56px);
			}
			@media #{$breakpoint-medium} {
				margin-top: 0;
			}
//
//             &.product-fancy::after {
//                 content: url('/images/icon_rice_stem.svg');
//                 position: absolute;
//                 left: 0;
//                 width: 100%;
//                 bottom: -75px;
//                 @media #{$breakpoint-medium} {
//                     bottom: -90px;
//                 }
//             }
//
//             &.recipe-fancy {
//                 margin-bottom: 50px;
//                 &::after {
//                     content: url('/images/icon_rice_stem.svg');
//                     position: absolute;
//                     left: 0;
//                     width: 100%;
//                     bottom: -35px;
//                     @media #{$breakpoint-medium} {
//                         bottom: -55px;
//                     }
//                 }
//             }
		}


		p.product-type {
			@extend h4;
			margin: 10px 0 0 0;
			color: $brown;
		}
	}

	p.description {
		line-height: 1.3em;
		text-align: center;
	}

	p#recipe-filters {
		text-align: center;
		margin: 0;
		padding-bottom: 30px;
		font-size: rem(14px);
		@media #{$breakpoint-medium} {
			font-size: rem(16px);
		}
	}

	img#product-mini-logo {
		max-height: 70px;
		margin: 20px 0;
	}
	img#product-food-logos {
		padding-bottom: 30px;
		@media #{$breakpoint-medium} {
			padding-bottom: 60px;
		}
	}

	small, p {
		color: $gray;
		a { color: $gray; }
	}
	small {
		font-size: 16px;
	}

	.sharables {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		@media #{$breakpoint-medium} {
			flex-direction: row;
			flex-wrap: nowrap;
			width: 90%;
		}
		@media #{$breakpoint-large} {
			flex-direction: row;
			flex-wrap: nowrap;
			width: 70%;
		}

		a {
			text-decoration: none;

			&.primary {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin: 0;
				width: 100%;
				@media #{$breakpoint-medium} {
					min-width: 175px;
					justify-content: space-evenly;
				}

				i {
					color: $white !important;
					font-size: 25px;
					padding-left: 10px;
					@media #{$breakpoint-medium} {
						padding-left: 0;
					}
				}
			}

			&:not(.button) {
				padding: 0 10px;
			}
		}

		i {
			color: $blue;
			font-size: 20px;
			@media #{$breakpoint-medium} {
				font-size: 35px;
			}
		}

		span {
			color: $gray;
			font-family: $tradegothic;
			display: inline-flex;
			align-items: center;
			padding: 10px 0;
			@media #{$breakpoint-medium} {
				flex-basis: 15%;
			}

			&.has-button {
				width: 100%;
				display: flex !important;
				flex-basis: 100%;
				@media #{$breakpoint-medium} {
					flex-basis: 15% !important;
					display: inline-flex !important;
				}
			}
		}

		.buttons {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			a { margin-bottom: 15px; }
			@media #{$breakpoint-medium} {
				flex-direction: row;
				a { margin-bottom: 0; }
			}
		}
	}
}

.product-details {
    .bg-text {
        @extend h6;
        color: $green;
        text-align: center;
        width: 100%;
        @media #{$breakpoint-xlarge} {
            margin-bottom: rem(10px);
        }
    }
}

.ingredients2 {
	text-align: left !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	@media #{$breakpoint-medium} {
		padding-left: 75px !important;
		padding-right: 75px !important;
	}

	h2, h3 {
		padding: 10px 0;
		margin: 0;
	}

	h4 {
		@extend h3;
	}

	.list {
		padding: 0 !important;
		display: block;
		@media #{$breakpoint-medium} {
			display: grid;
		}

		p {
			padding: 0;
			margin-top: 0;
			font-size: rem(13px);
			@media #{$breakpoint-medium} {
				font-size: rem(16px);
			}
		}

		ul {
			list-style: none;
			padding: 0 0 0 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			max-width: 400px;
		}

		li {
			font-family: $tradegothic;
			margin: 5px 0;
			font-size: rem(13px);
			@media #{$breakpoint-medium} {
				font-size: rem(16px);
			}
		}

		div.has-separator {
			border-right: 1px solid $gray;
			max-width: 65%;
		}
	}
}

#shop-local, #shop-online {
	padding: 25px 15px 10px;
	border: 1px solid $gray-light2;
	margin-bottom: 10px;
	width: 100%;
	@media #{$breakpoint-medium} {
		width: 85%;
	}

	span {
		@extend h4;
		display: block;
		text-align: center;
		margin-bottom: 10px;
		@media #{$breakpoint-medium} {
			margin-bottom: 0;
		}
	}

	form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		@media #{$breakpoint-medium} {
			flex-direction: row;
			padding-top: 25px !important;
			padding-bottom: 25px !important;
		}

		.inputs {
			flex-basis: 65%;
			margin-bottom: 20px;
			min-height: 40px;
			@media #{$breakpoint-medium} {
				margin-bottom: 0;
			}
		}
		input:not([type='radio']) {
			display: block;
			font-size: rem(14px);
			color: $evergreen !important;
			padding: 10px;
			border: 1px solid #d3d3d3 !important;
			font-family: $tradegothic;
			font-style: normal;
			width: 100%;
			height: 100%;
			@media #{$breakpoint-medium} {
				font-size: rem(18px);
			}
		}
		.buttons {
			flex-basis: 30%;
			display: flex;
			flex-direction: column;
		}

		.center {
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 100%;

			label {
				font-size: rem(18px);
				@media #{$breakpoint-medium} {
					font-size: rem(20px);
				}
			}

			.button-has-quantity {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				padding-top: 20px;
				@media #{$breakpoint-medium} {
					flex-direction: row;
					max-height: 80px;
					button {
						margin-left: 20px !important;
					}
				}

				.quantity-selector input {
					flex-basis: 50%;
					border-top: 1px solid #d3d3d3 !important;
					border-bottom: 1px solid #d3d3d3 !important;
					border-left: none !important;
					border-right: none !important;
				}
			}
		}
	}
}

.sub-images {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 20px;
	justify-content: flex-start;
    column-gap: 1rem;
    row-gap: 2rem;
    @media #{$breakpoint-medium} {
        gap: 1rem;
    }

	a, button {
		display: flex;
		border: 1px solid $blue;
		position: relative;
		flex-basis: 21%;
        background-color: #fff;
        min-height: 65px;
        align-items: center;
        margin: 0;
		@media #{$breakpoint-medium} {
			flex-basis: 11%;
            min-height: 75px;
		}

		img {
			width: 100%;
			height: 100%;

            &.has-video {
                opacity: 30%;
            }
		}

        &:hover {
            cursor: pointer;
        }

		&.active::after {
			content: '';
			position: absolute;
			width: 95%;
			bottom: -15px;
			left: -1px;
			border: 3px solid $blue;
		}

        svg {
            position: absolute;
            width: 35px;
            left: 0;
            right: 0;
            margin: 0 auto;
            stroke: $orange;
        }
	}
}

.quantity-selector {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-bottom: 10px;
	align-items: center;
	min-height: 60px;
	max-height: 60px;
	@media #{$breakpoint-medium} {
		margin-bottom: 0;
	}

	input {
		flex-basis: 50%;
		border-top: 1px solid #d3d3d3 !important;
		border-bottom: 1px solid #d3d3d3 !important;
		border-left: none !important;
		border-right: none !important;
		text-align: center;
		margin: 0;
		overflow: auto;
		min-height: 60px;
		max-height: 60px;
	}
	.quantity-button {
		display: flex;
		align-items: center;
		border: 1px solid #d3d3d3;
		padding: 10px;
		font-size: 25px;
		flex-basis: 25%;
		justify-content: center;
		min-height: 60px;
		max-height: 60px;
		@media #{$breakpoint-medium} {
			padding: 14px;
		}
		&:hover {
			cursor: pointer;
		}
	}
}

.product-search {
    background-color: #f8f8f7;

    a.has-image img {
        background: #fff;
        border: 1px solid #fff;
    }

    .slide:hover {
        a.has-image img {
            border-color: $green;
        }

        a.product-title {
            color: $green;
        }
    }
}

// ---------------------------------------- //

.ingredients-instructions {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    background-image: url('/images/ralston-background-illustration.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 45px 0 30px 0;
    @media #{$breakpoint-large} {
        padding: 80px 45px 100px 45px;
        grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr 1fr 1fr;
    }

    &.only {
        @media #{$breakpoint-large} {
            bottom: -75px;
            margin-top: -75px;
        }
    }

    h2 {
        margin: 0 !important;
        color: $green;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }

    h3 {
        color: $orange;
        font-family: $arquitecta;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 41px; /* 205% */
        text-transform: uppercase;
        margin: 0 !important;
    }

    .ingredients {
        padding: 0 20px;
        @media #{$breakpoint-large} {
            padding: 0;
            grid-column: span 2 / span 2;
        }
    }

    .instructions {
        padding: 0 20px;
        @media #{$breakpoint-large} {
            padding: 0;
            grid-column: span 4 / span 4;
        }
    }

    div.border {
        display: flex;
        justify-content: center;

        span {
            border-bottom: 1px solid $orange;
            margin: 15px 0 20px 0;
            @media #{$breakpoint-large} {
                border-left: 1px solid $orange;
                border-bottom: none !important;
                margin: 0 !important;
            }
        }
    }

    .ingredients-text, .instructions-text {
        display: flex;
        flex-direction: column;

        ul, p {
            margin-top: 0 !important;
            list-style: square;
            //list-style-image: url('/images/checkbox.png');

            li {
                color: $gray;
                font-family: $tradegothic;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                padding-left: 8px;

                &::marker {
                    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' height='18' width='18' viewBox='0 0 448 512'><path d='M384 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l320 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z'/></svg>");
                }
            }
        }

        p {
            font-size: rem(18px) !important;
            @media #{$breakpoint-medium} {
                font-size: rem(20px) !important;
            }
        }
    }
}

#recipe-sharing {
    .recent-recipes {
        h2 {
            grid-column: span 2 / span 2;
            text-align: center;
            margin: 0 !important;
        }

        @media #{$breakpoint-large} {
            margin-top: -75px;
            padding-top: 125px !important;

            h2 {
                grid-column: span 4 / span 4;
            }
        }
    }
}

.recipe-share-banner {
    position: relative;
    margin: 185px 0 0 0;
    background-color: $green;
    @media #{$breakpoint-large} {
        margin: 50px 0;
    }

    .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 15px;
        position: relative;
        @media #{$breakpoint-large} {
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 15px;
            max-width: 40rem;
            margin: 0 auto;
        }

        .bowl {
            position: absolute;
            width: 100%;
            max-width: 250px;
            top: -13rem;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            @media #{$breakpoint-large} {
                top: -7rem;
                left: -18rem;
                right: unset;
                margin: 0 !important;
                max-width: 300px;
            }
        }

        p {
            color: $white;
            text-align: center;
            font-family: $arquitecta;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            margin: 0;
            @media #{$breakpoint-large} {
                margin-left: 75px !important;
            }
        }

        #download-recipe {
            i {
                margin-right: 5px;
            }
        }

        .share-links {
            margin: 1rem 0;
            @media #{$breakpoint-large} {
                margin: 0 1.25rem 0 1.75rem;
            }

            a {
                color: $white;
                text-decoration: none !important;
            }

            svg, i {
                stroke: $white;
                font-size: 1.5rem;
            }

            * + * {
                margin-left: 1rem;
                @media #{$breakpoint-large} {
                    margin-left: 0.75rem;
                }
            }
        }
    }
}

// ---------------------------------------- //

.variety-picker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    .options {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        p {
            text-align: center;
            margin: 0 !important;
        }

        .select {
            select {
                min-width: 300px;
                padding: 19px 35px 13px 20px;
                line-height: 24px;
                font-size: rem(18px);
                @media #{$breakpoint-medium} {
                    min-width: 400px;
                    font-size: rem(20px);
                }
            }

            &.selected {
                select {
                    border: 1px solid $green;
                    color: $green;
                }

                &::after {
                    border-color: $green;
                }
            }
        }
    }

    button:disabled {
        &:hover {
            cursor: not-allowed !important;
        }
    }
}
