footer {
  display: block;
  width: 100%;

  // -----------------------------------
  // Upper Section (Green)
  // -----------------------------------
  .footer--section-green {
    display: block;
    @include container;
    background-color: $evergreen;
    padding: 50px 0;

    .wrapper {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      padding: 0 30px;
      width: 100%;
      @media #{$breakpoint-medium} {
        flex-direction: row;
        align-items: center;
        max-width: 800px;
      }
      @media #{$breakpoint-large} {
        max-width: 1024px;
        margin: 0 auto;
      }
      @media #{$breakpoint-xlarge} {
        max-width: 1350px;
        margin: 0 auto;
      }
    }
  }

  .branding-contact {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;
    @media #{$breakpoint-medium} {
      flex-direction: row;
      margin: 0;
      align-items: center;
    }

    img {
      min-width: 120px;
      max-width: 120px;
      @media #{$breakpoint-medium} {
        min-width: 160px;
        max-width: 160px;
      }
    }

    p {
      color: white;
      font-size: rem(16px);
      padding: 0;
      @media #{$breakpoint-medium} {
        padding: 0 25px 0 30px;
      }
      @media #{$breakpoint-large} {
        padding: 0 25px 0 60px;
      }

      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .secondary-links {
    .text-link {
      @extend h3;
      display: inline-block;
      font-size: rem(18px);
      color: $white;
      text-decoration: none;
      margin: 0 40px 20px 0;
      &:last-of-type {
        margin-right: 0;
      }
      @media #{$breakpoint-large} {
        font-size: rem(24px);
        margin: 0 40px 35px 0;
      }
    }

    .social {
      display: flex;

      a {
        display: block;
        width: 35px;
        margin: 0 30px 0 0;
        text-decoration: none;
        &:last-of-type {
          margin-right: 0;
        }
        @media #{$breakpoint-medium} {
            margin: 0 15px 0 0;
        }
        @media #{$breakpoint-large} {
          width: 45px;
          margin: 0 40px 0 0;
        }

        i {
          font-size: 32px;
          color: white;
          display: block;
        }
      }
    }
  }

  // -----------------------------------
  // Lower Section (Gray)
  // -----------------------------------
  .footer--section-gray {
    display: block;
    background-color: $gray-light;
    background-image: url('/images/ralston-background-illustration.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 45px 20px 30px 20px;
    @media #{$breakpoint-medium} {
      padding: 80px 30px 65px 30px;
    }

    p {
      text-align: center;
      color: $brown;
      font-size: rem(14px);

      a {
        color: $brown;
      }
    }

    .footer--icons {
      @include container;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 37px auto;
      flex-wrap: wrap;
      @media #{$breakpoint-large} {
        flex-wrap: nowrap;
      }

      a {
        text-decoration: none;
        margin: 0;
        flex-basis: 50%;
        display: flex;
        justify-content: center;
        @media #{$breakpoint-small} {
          margin: 0 11px;
          flex-basis: auto;
        }
        img {
          max-width: 100px;
        }
      }
    }
  }
}

.juicer-feed iframe {
    display:block;
    margin:0 auto;
    max-height: 575px;
    overflow: scroll;
    height: 1000px;
}
