// -----------------------------------
// Featured Recipes
// -----------------------------------
.featured-recipes {
    @include container;
    text-align: center;
    padding: 30px 0 80px 0;
    background-image: url('/images/ralston-background-illustration.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    @media #{$breakpoint-medium} {
        padding: 80px 0 80px 0;
    }

    h2 {
        margin: 0.5em;
    }

    p {
        font-size: rem(13px);
        padding: 0 20px 20px 20px;
        margin-top: 10px;
        line-height: 1.35rem;
        @media #{$breakpoint-medium} {
            font-size: 1.25rem !important;
            max-width: rem(1200px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .grid-wrapper {
        padding-top: 24px;
    }

    .item {
        p {
            font-size: rem(13px) !important;
            line-height: 1.35rem;
            @media #{$breakpoint-medium} {
                font-size: 1.25rem !important;
            }
        }

        a.product-image {
            @media #{$breakpoint-medium} {
                min-height: 396.5px;
                display: flex;
                align-items: center;
            }
        }
    }
}

// -----------------------------------
// Rice Varieties
// -----------------------------------
.rice-varieties {
    @include container;
    text-align: center;
    padding: 30px 0 50px 0;
    @media #{$breakpoint-medium} {
        padding: 50px 0 65px 0;
    }

    p {
        display: block;
        max-width: 1435px;
        width: 100%;
        margin: 12px auto 0 auto;
        padding: 0 25px;
        font-size: rem(16px);
        color: $gray;
        @media #{$breakpoint-medium} {
            font-size: rem(18px);
            margin: 0 auto;
            max-width: rem(1200px);
        }
        @media #{$breakpoint-xlarge} {
            font-size: rem(20px);
        }
    }
}

// -----------------------------------
// Featured Products
// -----------------------------------
.featured-products {
    @include container;
    text-align: center;
    padding: 30px 0 80px 0;
    @media #{$breakpoint-medium} {
        padding: 80px 0 80px 0;
    }

    h2 {
        margin: 0.5em;
    }

    p {
        font-size: rem(13px);
        padding: 0 20px 20px 20px;
        margin-top: 10px;
        line-height: 1.35rem;
        @media #{$breakpoint-medium} {
            font-size: 1.25rem;
            max-width: rem(1200px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .two-mobile-four-desktop {
        column-gap: 20px;
        row-gap: 50px;
        grid-template-columns: 1fr 1fr;
        @media #{$breakpoint-large} {
            column-gap: 50px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media #{$breakpoint-xxlarge} {
            column-gap: 65px;
        }
    }

    .item {
        position: relative;
        max-width: 157px !important;
        @media #{$breakpoint-large} {
            max-width: unset !important;
        }

        .title {
            margin-bottom: 0 !important;
        }

        .product-subtitle {
            font-size: rem(12px) !important;
            @media #{$breakpoint-medium} {
                font-size: rem(13px) !important;
            }
        }

        .price {
            color: $green;
            font-family: $arquitecta;
            font-size: rem(20px) !important;
            @media #{$breakpoint-medium} {
                font-size: rem(32px) !important;
            }
        }

        .button {
            margin-top: 15px;
            min-width: fit-content !important;
            padding: 13px 12px 10px;
            font-size: rem(13px);
            @media #{$breakpoint-large} {
                min-width: 100% !important;
                padding: 18px 30px 15px;
                font-size: rem(16px);
            }
        }

        a.product-image {
            @media #{$breakpoint-medium} {
                min-height: 396.5px;
                display: flex;
                align-items: center;
            }
        }

        .floating {
            position: absolute;
            top: -10px;
            right: 0;
            max-width: fit-content;
            text-transform: uppercase;
            color: $white;
            border-radius: 0.5rem;
            font-family: $arquitecta-regular;
            padding: 10px 15px;
            line-height: 1;
            //letter-spacing: 0.01em;

            font-size: rem(12px) !important;
            @media #{$breakpoint-large} {
                top: -5px;
                right: 0;
                padding: 14px 19px;
                font-size: rem(13px) !important;
            }

            &.blue {
                color: $white;
                background: $blue;
            }

            &.darkblue {
                color: $white;
                background: $darkblue;
            }

            &.darkgreen {
                color: $white;
                background: $darkgreen;
            }

            &.gray {
                color: $black;
                background: $gray;
            }

            &.orange {
                color: $white;
                background: $orange;
            }

            &.purple {
                color: $white;
                background: $purple;
            }

            &.light-purple {
                color: $white;
                background: $lightpurple;
            }

            &.green {
                color: $white;
                background: $green;
            }

            &.lime {
                color: $black;
                background: $lime;
            }

            &.evergreen {
                color: $white;
                background: $evergreen;
            }

            &.teal {
                color: $black;
                background: $teal;
            }

            &.yellow {
                color: $white;
                background: $yellow;
            }
        }
    }

    .sliding-grid {
        position: relative;
        @media #{$breakpoint-large} {
            padding: 0 25px;
        }
        @media #{$breakpoint-xxlarge} {
            padding: 0 45px;
        }

        // --- BULLETS
        .bottom-nav {
            background: transparent;
            display: none;
            width: 100%;
            justify-content: center;
            @media #{$breakpoint-large} {
                display: flex;
            }

            button {
                background: $lime;
                height: 1em;
                width: 1em !important;
                max-width: 1em !important;
                min-width: 1em !important;
                display: inline-block;
                border-radius: 1em;
                padding: 7px !important;
                margin: 5px !important;
                color: transparent;

                &.active {
                    background: $orange;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        // --- ARROWS
        button.prev-slide-button,
        button.next-slide-button {
            display: inline-flex;
            text-decoration: none;
            text-align: center;
            min-width: 150px;
            padding: 10px 12px;
            font-size: rem(13px);
            justify-content: center;
            border: 1px solid $orange;
            background-color: white;
            color: $orange;
            &:hover {
                border: 1px solid $lime;
                background-color: white;
                color: $lime;
            }

            @media #{$breakpoint-large} {
                position: absolute;
                top: 45%;
                padding: 0;
                content: ' ';
                min-width: fit-content;
                font-size: 4em;
                z-index: 50;
                border: 0;
                background: transparent;

                &:hover {
                    border: 0 !important;
                    background: transparent !important;
                }
            }

            &:hover {
                cursor: pointer;
            }

            &[data-controls="prev"] {
                @media #{$breakpoint-large} {
                    left: 7px;
                    justify-content: flex-start;

                    &::after {
                        border: 5px solid $lime;
                        border-right: 0;
                        border-top: 0;
                        content: " ";
                        display: block;
                        height: .5em;
                        pointer-events: none;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                        -webkit-transform-origin: center;
                        -ms-transform-origin: center;
                        transform-origin: center;
                        width: .5em;
                        z-index: 5;
                        margin-left: 10px;
                    }
                }
            }

            &[data-controls="next"] {
                @media #{$breakpoint-large} {
                    right: 7px;
                    justify-content: flex-end;

                    &::before {
                        border: 5px solid $lime;
                        border-right: 0;
                        border-top: 0;
                        content: " ";
                        display: block;
                        height: .5em;
                        pointer-events: none;
                        -webkit-transform: rotate(-135deg);
                        -ms-transform: rotate(-135deg);
                        transform: rotate(-135deg);
                        -webkit-transform-origin: center;
                        -ms-transform-origin: center;
                        transform-origin: center;
                        width: .5em;
                        z-index: 5;
                        margin-right: 10px;
                    }
                }
            }

            span {
                @media #{$breakpoint-large} {
                    display: none;
                }
            }
        }
    }

    .ending-button {
        margin-top: 15px;
        width: 81%;
        @media #{$breakpoint-large} {
            margin-top: 25px;
            width: auto;
        }
    }
}

// -----------------------------------
// Column Callouts
// -----------------------------------
.column-callouts {
    text-align: center;
    padding: 30px 0 80px 0;
    @media #{$breakpoint-medium} {
        padding: 80px 0 80px 0;
    }

    .inner {
        @include container;
    }

    .grid {
        padding-bottom: 0 !important;
    }
    &.two-mobile-four-desktop {
        .item {
            max-width: 157px !important;
            @media #{$breakpoint-large} {
                max-width: unset !important;
            }
        }
    }

    h2 {
        margin: 0.5em;
    }

    p {
        font-size: rem(13px);
        padding: 0 20px 20px 20px;
        margin-top: 10px;
        line-height: 1.35rem;
        @media #{$breakpoint-medium} {
            font-size: 1.25rem;
            max-width: rem(1200px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .item {
        position: relative;

        .title {
            width: 100% !important;
            @media #{$breakpoint-large} {
                margin-top: 35px !important;
            }
        }

        .button {
            margin-top: 15px !important;
            max-width: 100% !important;
            padding: 13px 12px 10px;
            font-size: rem(13px);
            @media #{$breakpoint-large} {
                max-width: fit-content !important;
                padding: 18px 30px 15px;
                font-size: rem(16px);
            }
        }

        .image {
            @media #{$breakpoint-large} {
                display: flex;
                align-items: center;
            }
        }
    }

    &.darkblue {
        color: $white;
        background: $darkblue;

        h2,
        .inner p,
        .inner .title {
            color: $white;
        }
    }

    &.darkgreen {
        color: $white;
        background: $darkgreen;

        h2,
        .inner p,
        .inner .title {
            color: $white;
        }
    }

    &.gray {
        color: $gray-light;
        background: $gray;

        h2,
        .inner p,
        .inner .title {
            color: $gray-light;
        }
    }

    &.light-gray {
        background: $gray-light;
    }
}
