.star-rating {
    display: flex;
    margin: 15px 0 15px 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    i {
        font-size: 16px;
        @media #{$breakpoint-medium} {
            font-size: 20px;
        }
    }

    a {
        position: absolute;
        font-size: 14px;
        bottom: -30px;
        color: $darkblue;
        font-family: $tradegothic;
    }
}
