#recipe-reviews, #product-reviews {
    padding-top: 50px;
    background-color: $gray-light;

    #review, #review-comments {
        padding: 0;
        @media #{$breakpoint-medium} {
            padding: 25px;
        }

        h1 {
            margin: 0;
            padding-bottom: 20px;
        }
    }

    #review-comments {
        .review {
            margin-bottom: 20px;
            @media #{$breakpoint-medium} {
                margin-bottom: 35px;
            }

            p {
                margin: 0;
                &.title {
                    text-transform: uppercase;
                    span {
                        font-weight: bold;
                    }
                }
            }

            .star-rating {
                margin: 5px 0;
                justify-content: start;
                align-items: center;
            }
        }
    }
}

#recipe-reviews {
    #review-comments {
        .review .star-rating {
            display: none;
        }
    }
}
