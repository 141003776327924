#where-to-buy {
    display: flex;
    flex-direction: row;
}

#storepoint-container {
    flex-basis: 100%;
    height: 100% !important;
    @media #{$breakpoint-large} {
        height: inherit !important;
    }

    // Inputs
    form#storepoint-search {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        @media #{$breakpoint-large} {
            flex-direction: row;
        }

        h1, #shop-online-link {
            display: none;
        }

        #storepoint-search-bar-container {
            margin-bottom: 15px;
            width: 100%;
            @media #{$breakpoint-large} {
                margin-bottom: 0;
                width: auto;
            }

            #storepoint-clear-search { top: 13px; }
            input {
                display: block;
                font-size: rem(14px) !important;
                color: $blue !important;
                padding: 10px 35px 6px 10px !important;
                border: 1px solid $blue !important;
                border-radius: 0 !important;
                line-height: 1 !important;
                font-family: $tradegothic !important;
                font-style: normal !important;
                width: 100% !important;
                height: 100% !important;
                @media #{$breakpoint-large} {
                    width: unset !important;
                    font-size: rem(18px) !important;
                }
            }
        }

        #storepoint-radius-dropdown {
            margin-bottom: 0;
            font-family: $tradegothic;
            padding: 10px 35px 6px 10px !important;
            border: 1px solid $blue !important;
            border-radius: 0 !important;
            font-size: rem(18px);
            min-width: 232px;
            color: $blue;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-left: 0;
            &:hover {
                cursor: pointer;
            }
            @media #{$breakpoint-large} {
                width: auto;
                margin-left: 10px;
            }

            ul {
                z-index: 30;
            }

            &.active {
                ul {
                    opacity: 1 !important;
                    z-index: 55;
                }
            }
        }

        #storepoint-searchbar-submit {
            @extend .button;
            font-style: normal;
            margin: 10px 0 0;
            z-index: 50;
            @media #{$breakpoint-large} {
                margin: 0;
            }
        }
    }

    // Locator list
    #storepoint-panel {
        .storepoint-location {
            margin-bottom: 0 !important;
            border: 0;
            border-bottom: 1px solid $gray-light2;;

            &:hover {
                background: $gray-light;
            }

            .storepoint-name {
                color: $green;
                font-family: $arquitecta;
                font-size: rem(16px);
                text-transform: uppercase;
                letter-spacing: 0.05em;
                @media #{$breakpoint-medium} {
                    font-size: rem(22px);
                }
            }
            .storepoint-address {
                font-family: $tradegothic;
                font-size: rem(14px);
                line-height: 1.45em;
                color: $gray;
                @media #{$breakpoint-medium} {
                    font-size: rem(16px);
                    line-height: 1.65em;
                }
            }
        }
    }

    // Map
    #storepoint-map {
        width: 100%;
    }


    // STARTING POSITION
    &.not-active {

        // Inputs
        form#storepoint-search {
            flex-direction: column;
            background: $green;
            justify-content: center;
            align-items: center;
            padding: 75px 20px 50px 20px !important;
            width: 100%;
            height: 100%;
            @media #{$breakpoint-large} {
                width: 35%;
                float: left;
                min-height: 600px;
            }

            #storepoint-search-bar-container,
            #storepoint-radius-dropdown {
                margin: 0;
                margin-bottom: 20px;
                display: flex;
                width: 100%;
                input {
                    width: 100% !important;
                }
                @media #{$breakpoint-large} {
                    width: 250px;

                }
            }

            #storepoint-radius-dropdown {
                margin-bottom: 40px;
            }

            h1, #storepoint-searchbar-submit, #shop-online-link {
                display: flex;
                margin-bottom: 35px !important;
                width: 100%;
                @media #{$breakpoint-large} {
                    width: auto;
                }
            }
            h1 {
                text-align: center;
                color:  white;
                margin-top: 35px;
                position: relative;
                justify-content: center;
                text-transform: uppercase;
                padding-top: 10px;
                padding-bottom: 20px;
                @media #{$breakpoint-medium} {
                    font-size: rem(56px);
                }

            }

            #storepoint-searchbar-submit, #shop-online-link {
                margin-bottom: 15px !important;
            }
        }

        // Locator list
        #storepoint-panel {
            display: none;
        }

        // Map
        #storepoint-map {
            //background-image: url('/images/where_to_buy.jpg');
            background-position: center;
            background-size: cover;
            height: 100% !important;
            position: relative !important;
            min-height: 300px;
            width: 100%;
            @media #{$breakpoint-large} {
                position: inherit !important;
                min-height: inherit;
                width: 65%;
            }

            #storepoint-map-container {
                //visibility: hidden;
            }
        }
    }
}

