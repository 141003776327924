.grid {
    display: grid;
    grid-gap: 20px;
    @include container;
    padding: 0 20px 35px 20px;
    @media #{$breakpoint-medium} {
        padding: 0 35px 50px 35px;
    }

    &.two-mobile-four-desktop {
        grid-template-columns: 1fr 1fr;
        @media #{$breakpoint-large} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &.one-mobile-two-desktop {
        grid-template-columns: 1fr;
        @media #{$breakpoint-large} {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.one-mobile-three-desktop {
        grid-template-columns: 1fr;
        @media #{$breakpoint-large} {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.one-mobile-four-desktop {
        grid-template-columns: 1fr;
        @media #{$breakpoint-medium} {
            grid-template-columns: 1fr 1fr;
        }
        @media #{$breakpoint-large} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .item {
        display: flex;
        max-width: 400px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        .stars-and-description {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-end;

            &.blog-read-more {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: flex-end;

                a.underlined {
                    text-align: center;
                }
            }
        }

        .blog-description {
            padding: 0 20px;
            margin: 0;
            line-height: 1.3em;
        }

        .title {
            @extend h4;
            display: block;
            color: $evergreen;
            text-align: center;
            width: 80%;
            margin: 20px auto 10px;
            line-height: 1.05em;
            text-decoration: none;
            &:hover {
                color: $green;
            }
        }

        .description {
            display: block;
            font-family: $tradegothic;
            font-size: rem(14px);
            color: $gray;
            text-align: center;
            width: 100%;
            padding: 10px 20px;
            @media #{$breakpoint-medium} {
                font-size: rem(16px);
            }

            a {
                color: $gray;
            }
        }

        a.underlined {
            color: $gray;
        }
    }

    &.search {
        background-color: #f8f8f7;
        padding: 30px 20px 30px 20px;
        @media #{$breakpoint-medium} {
            padding: 50px 100px 50px 100px;
            column-gap: 50px;
            grid-gap: 50px;
        }

        .item {
            background: white;
            padding-bottom: 20px;
            border: 1px solid #fff;
            @media #{$breakpoint-medium} {
                padding-bottom: 50px;
            }

            img {
                padding: 20px;
            }

            &:hover {
                border-color: $green;

                a.title {
                    color: $green;
                }
            }
        }
    }
}
