.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding-bottom: 5px;
    background-position: bottom;
    background-size: cover;
    max-height: 150px;
    min-height: 150px;
    background-color: $blue;
    @media #{$breakpoint-medium} {
        max-height: 300px;
        min-height: 300px;
        background-position: center;
    }

    img {
        display: block;
        position: relative;
        z-index: 1;
        margin: 0 auto;
    }

    .title {
        @extend h1;
        display: block;
        font-size: rem(20px);
        padding: 0 30px;
        margin: 0 0 0 0;
        z-index: 2;
        color: white;
        background: transparent;

        @media all and (min-width: 480px) {
            font-size: rem(26px);
        }
        @media #{$breakpoint-medium} {
            font-size: rem(40px);
//             text-shadow: 0px 0px 36px rgba(0,0,0,0.75);
        }
        @media #{$breakpoint-xlarge} {
            width: 70%;
            font-size: rem(60px);
        }
        @media #{$breakpoint-mega} {
            width: 60%;
        }
    }
}

.glamour {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding-bottom: 5px;
    background-position: bottom;
    background-size: cover;
    //max-height: 300px;
    //min-height: 300px;
    background-color: $gray-dark;
    overflow: hidden;
    @media #{$breakpoint-medium} {
        //max-height: 300px;
        //min-height: 300px;
        background-position: center;
    }

    img {
        display: block;
        position: relative;
        z-index: 1;
        margin: 0 auto;
    }

    video {
        z-index: 1;
        position: absolute;
        //top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .outer {
        position: relative;
        margin: 50px auto;
        max-width: 92%;
        padding: 30px 15px;
        z-index: 2;
        @media #{$breakpoint-medium} {
            margin-top: 175px;
            margin-bottom: 175px;
            padding: 50px;
        }
        @media #{$breakpoint-large} {
            margin-top: 300px;
            margin-bottom: 300px;
            max-width: 800px;
            min-width: 800px;
        }

        &.show:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $white;
            opacity: 80%;
        }

        .inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 3;

            .description, a {
                z-index: 3;
            }
        }
    }

    .title {
        @extend h2;
        display: block;
        font-size: rem(26px);
        padding: 0 15px 25px 15px;
        margin: 0 0 0 0;
        z-index: 3;
        background: transparent;
        @media #{$breakpoint-large} {
            font-size: rem(40px);
        }
    }
}
