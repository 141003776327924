// -----------------------------------
// Side by Side Content (text/image)
// -----------------------------------
.side-by-side {
    display: block;
    position: relative;
    padding: 0;
    @include container;
    @media #{$breakpoint-medium} {
        display: flex;
        padding: 35px 0;
    }

    .text {
        display: flex;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding: 60px 35px;
        align-items: center;
        @media #{$breakpoint-medium} {
            width: 50%;
        }

        .inner-wrapper {
            display: block;
            position: relative;
            z-index: 2;
            max-width: 570px;
            width: 100%;
            margin: 0 auto;
        }

        h2 {
            font-size: rem(35px);
            color: $brown;
            @media #{$breakpoint-medium} {
                font-size: rem(50px);
            }
            margin: 0;
        }

        h3 {
            margin: 10px 0 0;
        }

        p {
            color: $gray;
            font-size: rem(16px);
            padding-bottom: 10px;
            @media #{$breakpoint-medium} {
                font-size: rem(20px);
            }
        }

        a {
            margin: 0;
        }
    }

    .image {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        @media #{$breakpoint-medium} {
            width: 50%;
            z-index: 2;
        }
    }

    &.image-left {
        @media #{$breakpoint-medium} {
            flex-direction: row-reverse;
        }
    }

    &.gray {
        background-color: $gray-light;
        .bg-text {
            z-index: 0 !important;
        }
    }

    img.headline-image {
        max-width: 340px;
    }

    .bg-text {
        display: block;
        position: absolute;
        z-index: -1;
        top: 20%;
        //left: 50%;
        transform: rotate(-13deg);
        letter-spacing: -0.02em;
        line-height: 0.8em;
        font-family: $stash;
        font-size: rem(88px);
        color: $yellow;
        opacity: 0.10;
        @media #{$breakpoint-medium} {
            font-size: rem(120px);
            left: 5%;
        }
        @media #{$breakpoint-large} {
            font-size: rem(150px);
        }
        @media #{$breakpoint-xlarge} {
            font-size: rem(175px);
        }
        @media #{$breakpoint-mega} {
            font-size: rem(220px);
        }
    }

}
