
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfont(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Arquitecta SemiBold by Latinotype
 * URL: https://www.myfonts.com/products/semibold-arquitecta-608028
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Stash Bold by J Foundry
 * URL: https://www.myfonts.com/products/bold-stash-220426
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Novecento Sans Wide Bold by Synthview
 * URL: https://www.myfonts.com/products/wide-bold-novecento-140261
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Trade Gothic Bold Condensed #20 by Linotype
 * URL: https://www.myfonts.com/products/bold-condensed-no-20-63882-trade-gothic-368962
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */


@font-face {
  font-family: "NovecentoSansWideBold";
  src: url('/fonts/NovecentoSansWideBold/font.woff2') format('woff2'), url('/fonts/NovecentoSansWideBold/font.woff') format('woff');
}
@font-face {
  font-family: "ArquitectaSemiBold";
  src: url('/fonts/ArquitectaSemiBold/font.woff2') format('woff2'), url('/fonts/ArquitectaSemiBold/font.woff') format('woff');
}
@font-face {
  font-family: "TradeGothicBoldCondensed20";
  src: url('/fonts/TradeGothicBoldCondensed20/font.woff2') format('woff2'), url('/fonts/TradeGothicBoldCondensed20/font.woff') format('woff');
}
@font-face {
  font-family: "StashBold";
  src: url('/fonts/StashBold/font.woff2') format('woff2'), url('/fonts/StashBold/font.woff') format('woff');
}
@font-face {
  font-family: "TradeGothicLight";
  src: url('/fonts/TradeGothicLight/font.woff2') format('woff2'), url('/fonts/TradeGothicLight/font.woff') format('woff');
}
@font-face {
  font-family: "TradeGothicBoldCondensed18";
  src: url('/fonts/TradeGothicProCondensed18/font.woff2') format('woff2'), url('/fonts/TradeGothicProCondensed18/font.woff') format('woff');
}


