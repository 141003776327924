#breadcrumbs {
    padding: 10px 20px 0;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 500;
    margin-bottom: -30px;
    @media #{$breakpoint-medium} {
        margin-bottom: -60px;
        padding: 40px 30px 0;
    }

    a {
        @extend h5;
        text-decoration: none;
        // color: rgba($black, .8);
        color: white;
        font-size: rem(9px);
         @media #{$breakpoint-medium} {
            font-size: rem(11px);
         }
    }

    span.separator {
        display: inline-block;
        margin: 0 3px;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        // border-left: 4px solid rgba($black, .8);
        border-left: 3px solid white;
        @media #{$breakpoint-medium} {
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid white;
        }
    }

    .mobile-hidden {
        display: none !important;
        @media #{$breakpoint-medium} {
            display: inline-block !important;
        }
    }
}

#edit-page {
    position: absolute;
    right: 10px;
    top: 8px;
    @media #{$breakpoint-medium} {
        right: 0;
    }
}
