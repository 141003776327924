#job-postings {
    .job {
        border: 1px solid $gray;
        padding: 0 15px 10px 15px;

        h2 {
            text-align: center;
        }
    }
}
