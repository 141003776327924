// Ralston Logo
.site-logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 130px;
    @media #{$breakpoint-large} {
        width: 160px;
        margin-right: 25px;
    }

    img {
        max-height: 70px;
    }
}

header {
    position: sticky;
    top: 0;
    z-index: 1000;
    @media #{$breakpoint-large} {
        position: relative;
    }
}


// -----------------------------------
// Nav - Shalime Styles
// -----------------------------------
nav {
    display: block;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 15;
    box-shadow: 0 4px 13px 0 rgba(0,0,0,.10), 0 1px 4px 0 rgba(0,0,0,.10);

    .nav--text-link {
        position: relative;

        a {
            color: $brown;
            text-decoration: none;
            font-family: $arquitecta;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            line-height: 1;
        }

        .nav--children {
            display: none;
            position: absolute;
            left: -20px;
            right: 0;
            top: 50px;
            padding: 15px;
            background: white;
            text-align: left;
            width: 100%;
            min-width: max-content;
            border: 2px solid $gray-light;
            box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;

                li {
                    display: flex;
                    align-items: center;
                    padding: 12px 0;
                    margin-bottom: 3px;
                    border-bottom: 2px solid $gray-light;
                    width: 100%;
                    font-size: rem(13px);
                    @media #{$breakpoint-mega} {
                        font-size: rem(15px);
                    }
                }

                li:last-child {
                    border-bottom: none !important;
                    margin-bottom: 0 !important;
                }

                a {
                    color: $brown;
                    &:hover {
                        color: $green;
                    }
                }
            }
        }

        &:hover {
            a {
                color: $green;
            }

            .nav--children {
                display: flex;
            }
        }
    }

    .icons {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        a {
            text-decoration: none;
        }
    }
}


// Circular lime Icons
.circle-link {
    display: inline-flex;
    flex-shrink: 0;
    position: relative;
    width: 32px;
    height: 32px;
    background-color: $lime;
    border: 1px solid $lime;
    border-radius: 32px;
    margin: 0 5px;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $green;
        border: 1px solid $green;
    }
    @media #{$breakpoint-xlarge} {
        margin: 0 5px;
        width: 40px;
        height: 40px;
    }


    i, svg {
        color: white;
    }
}

.icon-link {
    display: inline-flex;
    flex-shrink: 0;
    position: relative;
    background-color: white;
    border: none !important;
    margin: 0 5px;
    justify-content: center;
    align-items: center;
    width: 32px;

    i, svg {
        color: $lime;
        max-width: 23px;
        width: 100%;
    }

    svg path {
        stroke: $lime;
    }

    &:hover {
        i, svg, svg path {
            color: $green;
            stroke: $green;
        }
    }
}

// -----------------------------------
// Nav - Desktop
// -----------------------------------
nav#navigation,
nav#desktop-navigation {
    display: flex;
    height: 55px;
    @media #{$breakpoint-large} {
        display: block;
        height: 70px;
    }

    .wrapper-1920 {
        position: relative;
        display: flex;
        justify-content: space-between;
        text-align: center;
        height: 70px;
    }

    .nav-left,
    .nav-right {
        display: flex;
        text-align: center;
        height: 58px;

        @media #{$breakpoint-large} {
            width: 100%;
            text-align: center;
            height: 70px;
        }

        .nav--text-link {
            padding-right: 20px;
            @media #{$breakpoint-large} {
                padding-right: 25px;
            }
        }
    }

    .nav-left {
        justify-content: flex-start;
    }

    .nav-right {
        justify-content: flex-end;
        max-width: fit-content;

        .nav--text-link:last-child {
            padding-right: 0 !important;
        }
    }

    // Text links in nav
    .nav--text-link {
        display: none;
        flex-direction: column;
        justify-content: center;
        font-size: rem(13px);
        @media #{$breakpoint-large} {
            display: flex;
        }

        &.primary {
            font-size: rem(15px);
            @media #{$breakpoint-mega} {
                font-size: rem(16px);
            }
        }
    }
}

// -----------------------------------
// Nav - Mobile
// -----------------------------------
#mobile-navigation-links,
#mobile-navigation {
    display: none;

    &.active {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        div {
            margin-bottom: 15px;
            position: relative;
            padding: 5px 20px;
            text-align: center;

            a {
                text-transform: uppercase;
                text-decoration: none;
                color: $brown;
                font-family: $arquitecta;
                font-size: rem(16px);

                &:hover {
                    color: $orange;
                }
            }

            &.has-children {
                background: $gray-light;
                padding-top: 15px;
                padding-bottom: 5px;
                margin: 0 20px 15px;

                ul {
                    list-style: none;
                    padding: 10px 0;
                    margin: 12px 0 0 0;
                    border-top: 1px solid $gray-light2;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 9px 0;
                        width: 100%;
                        font-size: rem(15px);
                    }
                }
            }
        }

        hr {
            width: 100%;
            margin-bottom: 15px;
        }

        .social {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            a {
                display: block;
                width: 35px;
                text-decoration: none;
                &:last-of-type {
                    margin-right: 0;
                }
                @media #{$breakpoint-medium} {
                    margin: 0 15px 0 0;
                }
                @media #{$breakpoint-large} {
                    width: 45px;
                    margin: 0 40px 0 0;
                }

                i {
                    font-size: 32px;
                    color: black;
                    display: block;
                }
            }
        }
    }
}



// Hamburger Menu Icon
button.target-burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    position: relative;
    border: none;
    background: none;

    @media #{$breakpoint-large} {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    &.active {
        ul.buns {
            li.bun {
                -webkit-transform: rotate(45deg) translateZ(0);
                transform: rotate(45deg) translateZ(0);
                &:last-child {
                    -webkit-transform: rotate(-45deg) translateZ(0);
                    transform: rotate(-45deg) translateZ(0);
                }
            }
        }
    }

    ul.buns {
        width: 35px;
        list-style: none;
        padding: 0;
        -webkit-transition: -webkit-transform 1s cubic-bezier(.23, 1, .32, 1), color 1s cubic-bezier(.23, 1, .32, 1);
        transition: transform 1s cubic-bezier(.23, 1, .32, 1), color 1s cubic-bezier(.23, 1, .32, 1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        color: #fff;

        li.bun {
            width: 100%;
            height: 2px;
            background-color: $orange;
            position: absolute;
            top: 50%;
            margin-top: -.75px;
            -webkit-transform: translateY(-3.75px) translateZ(0);
            transform: translateY(-3.75px) translateZ(0);
            -webkit-transition: -webkit-transform 1s cubic-bezier(.23, 1, .32, 1), background-color 1s cubic-bezier(.23, 1, .32, 1);
            transition: transform 1s cubic-bezier(.23, 1, .32, 1), background-color 1s cubic-bezier(.23, 1, .32, 1);
            &:last-child {
                -webkit-transform: translateY(3.75px) translateZ(0);
                transform: translateY(3.75px) translateZ(0);
            }
        }
    }
}

.checkout-nav-form-button {
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        visibility: hidden;
        &:hover {
            cursor: pointer;
        }
    }
}
