.modal {
    position: relative;
    z-index: 1001;

    .backdrop {
        position: fixed;
        opacity: 75%;
        inset: 0;
        background-color: #6b7280;
    }

    .outer {
        position: fixed;
        inset: 0;
        z-index: 1001;
        width: 100vw;
        overflow-y: auto;

        .wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            min-height: 100%;
            @media #{$breakpoint-large} {
                padding: 0;
            }

            .inner {
                position: relative;
                overflow: hidden;
                width: 100%;
                padding: 1rem;
                border-radius: 0.5rem;
                background-color: $teal;
                text-align: center;
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
                @media #{$breakpoint-large} {
                    padding: 1.5rem;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    max-width: 42rem;
                }

                .dismiss {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    // pr-4 pt-4

                    button {
                        background-color: transparent;
                        padding: 10px !important;
                        color: $gray-light;
                        min-width: fit-content !important;
                        &:focus {
                            outline: 2px solid transparent;
                            outline-offset: 2px;
                        }
                        &:hover {
                            color: $lightyellow;
                        }

                        svg {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }

                p.title {
                    font-size: rem(28px);
                    text-transform: uppercase;
                    margin: 0 !important;
                    letter-spacing: 0.045em;
                    color: $white;
                    @media #{$breakpoint-medium} {
                        font-size: rem(34px);
                    }
                }

                p {
                    font-size: rem(18px);
                    color: $white;
                    margin: 0 !important;
                    @media #{$breakpoint-medium} {
                        font-size: rem(20px);
                    }
                }

                .success {
                    background-color: $green;
                    color: white;
                    font-size: rem(18px);
                    padding: 1rem 1rem;
                    margin: 1rem auto !important;
                    @media #{$breakpoint-medium} {
                        font-size: rem(20px);
                    }

                    span {
                        font-weight: bold;
                        display: block;
                    }
                }

                .form {
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 0 !important;

                    label {
                        color: $white;
                    }

                    .inputs {
                        display: grid;
                        margin-bottom: 10px;
                        grid-template-columns: repeat(1, minmax(0, 1fr));

                        @media #{$breakpoint-large} {
                            gap: 15px;
                            grid-template-columns: repeat(2, minmax(0, 1fr));
                            max-width: 1024px;
                            width: 100%;
                            margin: 0 auto;

                            label {
                                text-align: left;
                            }
                        }
                    }

                    .has-captcha .g-recaptcha {
                        display: flex;
                        justify-content: center;
                    }

                    button:disabled {
                        cursor: not-allowed !important;
                        background-color: transparent !important;
                        border: 2px solid white !important;
                        color: white !important;
                        display: none;

                        &:hover {
                            cursor: not-allowed !important;
                        }
                    }
                }

                span.error {
                    background-color: $red;
                    font-family: $tradegothic;
                    color: white;
                    font-weight: bold;
                    font-size: rem(15px);
                    padding: 1rem;
                    margin: 0.5rem 0;
                    display: flex;
                    justify-content: center;
                    @media #{$breakpoint-large} {
                        font-size: rem(16px);
                        padding: 1rem;
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
