@use "sass:math";

* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    scroll-behavior: smooth;
}

body {
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    max-width: 1920px;
    position: relative;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.15);

    #body.hidden {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(33, 33, 33, 0.75);
            z-index: 9;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    //transition: color .17s;
}

i {
    font-style: normal;
}

iframe {
    width: 100%;
    height: 100%;
}

.wrapper-1920 {
    display: block;
    max-width: 1920px;
    width: 100%;
}

// Variables & Functions
// ---------------------
$radius: 0;
$baseFontSize: 16;
$lineHeight: 1.6;
$tilePadding: 20px;
$text-margin-bottom: 1em;
$defaultGridGap: 20px;
$textShadow: 0 0 60px #000;
$opacity: 0.2;

// REM CALC
@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return math.div($pixels, $context) * 1rem;
}

// Main Containment, 1920px width
@mixin container {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
}

// pre-set width breakpoints
@import 'breakpoints';

// Base
@import 'base/colors';
@import 'base/typography';
@import 'base/header';
@import 'base/footer';
@import 'base/forms';

// Layouts
@import 'layout/main';
@import 'layout/homepage';
@import 'layout/recipe_and_product';
@import 'layout/profile';

// Components
@import 'components/modal';
@import 'components/banners';
@import 'components/breadcrumbs';
@import 'components/button';
@import 'components/cart';
@import 'components/featured';
@import 'components/slider';
@import 'components/grid';
@import 'components/hero';
@import 'components/image_blocks';
@import 'components/newsletter';
@import 'components/nutrition';
@import 'components/reviews';
@import 'components/jobs';
@import 'components/locator_map';
@import 'components/side-by-side';
@import 'components/star-rating';

// Fonts
@import 'fonts/fonts';
@import 'fonts/icons';
