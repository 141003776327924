.nutrition {
    display: flex;
    flex-direction: column;
    background-color: $gray-light;
    padding: 20px;
    justify-content: space-around;
    align-items: center;
    @media #{$breakpoint-medium} {
        flex-direction: row;
        padding: 25px 50px;
    }

    .text {
        flex-basis: 100%;
        padding-bottom: 25px;
        @media #{$breakpoint-medium} {
            flex-basis: 60%;
            padding-bottom: 0;
        }

        p {
            margin: 0;
            font-size: rem(13px);
            @media #{$breakpoint-medium} {
                font-size: rem(15px);
            }
        }

        h2, h3, h4 {
            margin: 0 0 5px;
        }
    }

    img {
        align-self: center;
        padding: 20px 0;
    }
}
