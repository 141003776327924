//$phoreus: phoreuscherokee, sans-serif;
// $rockwell: rockwell, sans-serif;
// $gill: gill-sans-nova, sans-serif;

$novecento: NovecentoSansWideBold, Verdana, sans-serif;
$arquitecta: ArquitectaSemiBold, Verdana, sans-serif;
$arquitecta-regular: Arquitecta, Verdana, sans-serif;
$tradegothic: TradeGothicBoldCondensed18, Tahoma, sans-serif;
$tradegothic-bold: TradeGothicBoldCondensed20, Tahoma, sans-serif;
$stash: StashBold, Verdana, sans-serif;


h1, h2, h3, h4, h5 {
    font-family: $arquitecta;
    text-transform: uppercase;
    color: $green;
    line-height: 1;
    letter-spacing: 0.03em;
}

h1 {
  font-size: rem(32px);
  letter-spacing: 0.03em;
    @media #{$breakpoint-medium} {
        font-size: rem(60px);
    }
}

h2 {
  font-size: rem(24px);
  @media #{$breakpoint-medium} {
    font-size: rem(32px);
  }
  @media #{$breakpoint-large} {
    font-size: rem(40px);
  }
}

h3 {
    font-size: rem(18px);
    @media #{$breakpoint-medium} {
        font-size: rem(20px);
    }
    @media #{$breakpoint-large} {
        font-size: rem(26px);
    }
}

h4 {
    font-size: rem(14px);
    @media #{$breakpoint-medium} {
        font-size: rem(16px);
    }
    @media #{$breakpoint-large} {
        font-size: rem(20px);
    }
}

h5 {
    font-size: rem(16px);
}

h6 {
    font-family: $stash;
    color: $blue;
    line-height: 1.2;
    font-size: rem(24px);
    @media #{$breakpoint-medium} {
        font-size: rem(30px);
    }
    @media #{$breakpoint-large} {
        font-size: rem(40px);
    }
}

p, label {
  font-family: $tradegothic;
  font-size: rem(16px);
  line-height: 1.3em;
  color: $black;
  @media #{$breakpoint-medium} {
    font-size: rem(20px);
    line-height: 1.65em;
  }

  a {
    color: $green;
    &:hover {
      text-decoration: underline;
      color: $darkgreen;
    }
  }

  b, strong {
    font-family: $tradegothic-bold;
    font-size: 105%;
  }
}


li {
    font-family: $tradegothic;
    font-size: rem(14px);
    line-height: 1.3em;
    @media #{$breakpoint-medium} {
        font-size: rem(20px);
        line-height: 1.65em;
    }
    b, strong {
        font-family: $tradegothic-bold;
        font-size: 105%;
      }
}


// Adds Rice icon stem beneath the heading text
// .fancy {
//   display: inline-block;
//   text-align: center;
//   padding-bottom: 30px;
//   position: relative;
//
//   &::after {
//     content: url('/images/icon_rice_stem.svg');
//     position: absolute;
//     left: 0;
//     width: 100%;
//     bottom: -7px;
//     @media #{$breakpoint-xlarge} {
//         bottom: -12px;
//     }
//   }
// }

small {
    font-family: $tradegothic;
}

hr {
    border-top: 1px solid $gray-light2;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}
