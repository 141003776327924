.image-blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 25px;

    &:not(.count-1) {
        .block {
            @media #{$breakpoint-medium} {
                margin-bottom: 40px;
            }
        }
    }

    .block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        min-height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin-bottom: 5px;
        @media #{$breakpoint-medium} {
            min-height: 500px;
            padding: 30px 80px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(33, 33, 33, 0.4);
            z-index: 1;
        }

        &.half {
            flex-basis: 100%;
            @media #{$breakpoint-medium} {
                flex-basis: 49%;
            }
        }
        &.full {
            flex-basis: 100%;
        }

        &.has-video {
            padding: 0 !important;
        }

        h2 {
            color: white;
            margin: 0 0 20px 0;
            text-transform: uppercase;
            font-size: rem(24px);
            text-align: center;
            @media #{$breakpoint-medium} {
                font-size: rem(48px);
            }
        }
        p {
            font-family: $tradegothic;
            margin: 0;
            color: white;
            line-height: 1.5em;
            padding-bottom: 30px;
            text-align: center;
        }
        iframe, video {
            width: 100% !important;
            height: 100% !important;
        }

        h2, p, a, iframe, video {
            z-index: 2;
        }
    }
}

.has-video {
    width: 100%;
    height: 100%;

    iframe, video {
        max-width: 100vw;
        height: calc(100vw/1.77);
    }

    &.contained {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #2d2c2c;
        padding: 0;
        margin-top: 10px;
        @media #{$breakpoint-large} {
            padding: 35px 0;
        }

        iframe, video {
            @media #{$breakpoint-large} {
                height: 720px !important;
                max-width: 1280px !important;
            }
        }
    }
}
