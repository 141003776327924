// LIGHT
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 300;
    font-display: block;
    src: url("/icons/fa-light-300.eot");
    src: url("/icons/fa-light-300.eot?#iefix") format("embedded-opentype"), url("/icons/fa-light-300.woff2") format("woff2"), url("/icons/fa-light-300.woff") format("woff"), url("/icons/fa-light-300.ttf") format("truetype"), url("/icons/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300; }

// SOLID
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("/icons/fa-solid-900.eot");
    src: url("/icons/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/icons/fa-solid-900.woff2") format("woff2"), url("/icons/fa-solid-900.woff") format("woff"), url("/icons/fa-solid-900.ttf") format("truetype"), url("/icons/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900; }

// BRANDS
@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("/icons/fa-brands-400.eot");
    src: url("/icons/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/icons/fa-brands-400.woff2") format("woff2"), url("/icons/fa-brands-400.woff") format("woff"), url("/icons/fa-brands-400.ttf") format("truetype"), url("/icons/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
    font-family: 'Font Awesome 5 Brands';
    font-weight: 400; }

// ------------------------------------------------------------------------------------------------------------------ //

// --> NAVIGATION

// <i class="fal fa-shopping-cart"></i>
.fa-shopping-cart:before {
    content: "\f07a"; }

// <i class="fal fa-user"></i>
.fa-user:before {
    content: "\f007"; }

.fa-times:before {
    content: "\f00d"; }

// --> BRANDS

// <i class="fab fa-facebook-square"></i>
.fa-facebook-square:before {
    content: "\f082"; }

// <i class="fab fa-instagram"></i>
.fa-instagram:before {
    content: "\f16d"; }

// <i class="fab fa-pinterest"></i>
.fa-pinterest:before {
    content: "\f0d2"; }

// <i class="fab fa-twitter"></i>
.fa-twitter:before {
    content: "\f099"; }

// <i class="fab fa-youtube"></i>
.fa-youtube:before {
    content: "\f167"; }

// --> PRINT & COPY

// <i class="fal fa-print"></i>
.fa-print:after {
    content: "\f02f"; }

// <i class="fal fa-copy"></i>
.fa-copy:before {
    content: "\f0c5"; }

// --> STARS

// <i class="fal fa-star"></i>
.fa-star:before {
    color: $yellow;
    content: "\f005"; }

// <i class="fas fa-star"></i>
.fa-star:before {
    color: $yellow;
    content: "\f005"; }

// <i class="fas fa-star-half-alt"></i>
.fa-star-half-alt:before {
    color: $yellow;
    content: "\f5c0"; }

// --> MAP

// <i class="fas fa-map-marker-alt"></i>
.fa-map-marker-alt:after {
    content: "\f3c5"; }
